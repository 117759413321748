import GatsbyImage, { GatsbyImageProps } from 'gatsby-image';
import * as React from 'react';

export class GatsbyImageWrapper extends React.Component<{ outerWrapperClassName?: string } & GatsbyImageProps, any> {
	render() {
		const { outerWrapperClassName, ...props } = this.props;
		return (
			<div className={outerWrapperClassName ? outerWrapperClassName + ' gatsby-image-outer-wrapper' : 'gatsby-image-outer-wrapper'}>
				<GatsbyImage {...props} />
			</div>
		);
	}
}
