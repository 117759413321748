import * as React from 'react';

import * as styles from './UIAlert.module.scss';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { TweenMax } from 'gsap/dist/gsap.min';
import { EASE_CUSTOM_OUT } from '../utils/Constants';
import { getAbsolutePagePath } from '../utils/URLHelper';
import $ from 'jquery/dist/jquery.min';

//import $ from 'jquery/dist/jquery.min';

const initialState = { showAlertDialog: true };
type State = Readonly<typeof initialState>;

class UIAlert extends React.Component<{ showAlert?: boolean; pageTitle?: string; currLanguage?: string } & WrappedComponentProps, State> {
	readonly state: State = initialState;

	private alertBanner;
	private currScroll = 0;

	constructor(props) {
		super(props);
		this.closeAlertDialog = this.closeAlertDialog.bind(this);
	}

	private handleScroll = () => {
		this.currScroll = window.pageYOffset;
		if (this.currScroll > 75) {
			document.getElementById('background').style.position = 'fixed';
			document.getElementById('mainmenu').style.position = 'fixed';
			document.getElementById('fixedWrapper').style.position = 'fixed';
			document.getElementById('currLanguage').style.position = 'fixed';

			document.getElementById('background').style.top = '-75px';
			document.getElementById('mainmenu').style.top = '-75px';
			document.getElementById('fixedWrapper').style.top = '-75px';
			document.getElementById('currLanguage').style.top = '-75px';
			document.getElementById('searchWrapper').style.top = '-75px';
		} else {
			document.getElementById('background').style.position = 'absolute';
			document.getElementById('mainmenu').style.position = 'absolute';
			document.getElementById('fixedWrapper').style.position = 'absolute';
			document.getElementById('currLanguage').style.position = 'absolute';

			document.getElementById('background').style.top = '0px';
			document.getElementById('mainmenu').style.top = '0px';
			document.getElementById('fixedWrapper').style.top = '0px';
			document.getElementById('currLanguage').style.top = '0px';
			document.getElementById('searchWrapper').style.top = '0px';
		}
	};

	private showAlertDialog = () => {
		TweenMax.to(this.alertBanner, 1, { css: { marginTop: 75 }, ease: EASE_CUSTOM_OUT, force3D: true, delay: 0 });
		TweenMax.to('#background', 1, { css: { marginTop: 75 }, ease: EASE_CUSTOM_OUT, force3D: true, delay: 0 });
		TweenMax.to('#mainmenu', 1, { css: { marginTop: 75 }, ease: EASE_CUSTOM_OUT, force3D: true, delay: 0 });
		TweenMax.to('#fixedWrapper', 1, { css: { marginTop: 75 }, ease: EASE_CUSTOM_OUT, force3D: true, delay: 0 });
		TweenMax.to('#currLanguage', 1, { css: { marginTop: 75 }, ease: EASE_CUSTOM_OUT, force3D: true, delay: 0 });
		TweenMax.to('#searchWrapper', 1, { css: { marginTop: 75 }, ease: EASE_CUSTOM_OUT, force3D: true, delay: 0 });
		TweenMax.to('#siteWrapper', 1, { css: { marginTop: 75 }, ease: EASE_CUSTOM_OUT, force3D: true, delay: 0 });
	};

	// private alerttabbingfix = (event) => {
	// 	if (event.key == 'Enter') {
	// 		//this.listItemClickHandler(event)
	// 		this.closeAlertDialog.bind(this)
	// 		$("#vidhomepagecontrols svg").focus();
	// 		$("#alertclosebutton").attr("tabindex",-1)
	// 		$("#statementspagelink").attr("tabindex",-1)
	// 	}
	// };

	private closeAlertDialog = event => {
		if (event.key == 'Enter') {
			$('#vidhomepagecontrols svg').focus();
			$('#alertclosebutton').attr('tabindex', -1);
			$('#statementspagelink').attr('tabindex', -1);
		}
		if (event.key != 'Tab') {
			if (event) {
				if (event.target.id == 'alertclosebutton') {
					event.preventDefault();
				}
				this.setState({
					showAlertDialog: false
				});
				TweenMax.to(this.alertBanner, 0.5, { css: { marginTop: '-75px' }, ease: EASE_CUSTOM_OUT, force3D: true, delay: 0.2 });
				TweenMax.to('#siteWrapper', 0.5, { css: { marginTop: 0 }, ease: EASE_CUSTOM_OUT, force3D: true, delay: 0.2 });
				TweenMax.to('#background', 0.5, { css: { marginTop: 0 }, ease: EASE_CUSTOM_OUT, force3D: true, delay: 0.2 });
				TweenMax.to('#mainmenu', 0.5, { css: { marginTop: 0 }, ease: EASE_CUSTOM_OUT, force3D: true, delay: 0.2 });
				TweenMax.to('#fixedWrapper', 0.5, { css: { marginTop: 0 }, ease: EASE_CUSTOM_OUT, force3D: true, delay: 0.2 });
				TweenMax.to('#currLanguage', 0.5, { css: { marginTop: 0 }, ease: EASE_CUSTOM_OUT, force3D: true, delay: 0.2 });
				TweenMax.to('#searchWrapper', 0.5, { css: { marginTop: 0 }, ease: EASE_CUSTOM_OUT, force3D: true, delay: 2 });

				document.getElementById('background').style.position = 'fixed';
				document.getElementById('mainmenu').style.position = 'fixed';
				document.getElementById('fixedWrapper').style.position = 'fixed';
				document.getElementById('currLanguage').style.position = 'fixed';
			}
		}
	};

	componentDidMount() {
		this.showAlertDialog();
		window.addEventListener('scroll', this.handleScroll);
	}

	componentDidUpdate() {
		if (!this.state.showAlertDialog) {
			window.removeEventListener('scroll', this.handleScroll);
		}
	}

	public render() {
		return (
			<div>
				{
					<div className={styles.alertWrapper} ref={div => (this.alertBanner = div)}>
						<div className={styles.innerWrapper}>
							<h4>
								<FormattedMessage id="alert_title" />
							</h4>
							<p>
								<FormattedMessage id="alert_content" />
								<a id="statementspagelink" target="_blank" href={this.props.currLanguage !== 'ar-QA' ? 'https://' + getAbsolutePagePath('alert') : 'https://statements.qf.org.qa/ar'}>
									<FormattedMessage id="alert.link" />
								</a>
							</p>
							<a
								title={this.props.intl.formatMessage({ id: 'closealertdialogueboxclosebtntitle' })}
								aria-label={this.props.intl.formatMessage({ id: 'closealertdialogueboxclosebtnarialabel' })}
								id="alertclosebutton"
								className={styles.closeButton}
								href="#close-alert"
								onKeyDown={this.closeAlertDialog.bind(this)}
								onClick={this.closeAlertDialog.bind(this)}
							/>
						</div>
					</div>
				}
			</div>
		);
	}
}

export default injectIntl(UIAlert);
