import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import * as styles from './MainMenuSocialCopyright.module.scss';
import twitter from 'simple-icons/icons/twitter';
import facebook from 'simple-icons/icons/facebook';
import linkedin from 'simple-icons/icons/linkedin';
import instagram from 'simple-icons/icons/instagram';

interface Props {
	children?: any; 
	refSocialLinks?: HTMLAnchorElement[]; 
	footerCopyrightMessage?: string; 
	twitterLink?: string; 
	instagramLink?: string;
	connectModule?: boolean;
}

class MainMenuSocialCopyright  extends React.Component<Props & WrappedComponentProps> {
	render() {
		let { children, footerCopyrightMessage } = this.props;
		return (
			<div className={`${styles.socialCopyright} ${this.props.connectModule ? 'connectModule' : null}`}>
				<div className={styles.socialLinks}>
					<a
						//@ts-ignore:
						aria-label={this.props.intl.formatMessage({ id: 'mmsctwtarialabel' })}
						//aria-label= {FormattedMessage({ id: "mmsctwtarialabel"})}
						//@ts-ignore:
						title={this.props.intl.formatMessage({ id: 'mmsctwttitle' })}
						target="_blank"
						rel="noreferrer"
						href={`https://twitter.com/QF`}
						dangerouslySetInnerHTML={{
							__html: twitter.svg
						}}
					/>
					<a
						//@ts-ignore:
						aria-label={this.props.intl.formatMessage({ id: 'mmscfbarialabel' })}
						//@ts-ignore:
						title={this.props.intl.formatMessage({ id: 'mmscfbtitle' })}
						target="_blank"
						rel="noreferrer"
						href={`https://www.facebook.com/qatarfoundation`}
						dangerouslySetInnerHTML={{
							__html: facebook.svg
						}}
					/>
					<a
						//@ts-ignore:
						aria-label={this.props.intl.formatMessage({ id: 'mmsclinkedinarialabel' })}
						//@ts-ignore:
						title={this.props.intl.formatMessage({ id: 'mmsclinkedintitle' })}
						target="_blank"
						rel="noreferrer"
						href={`https://www.linkedin.com/company/qatarfoundation`}
						dangerouslySetInnerHTML={{
							__html: linkedin.svg
						}}
					/>
					<a
						//@ts-ignore:
						aria-label={this.props.intl.formatMessage({ id: 'mmscinstaarialabel' })}
						//@ts-ignore:
						title={this.props.intl.formatMessage({ id: 'mmscinstatitle' })}
						target="_blank"
						rel="noreferrer"
						href={`http://instagram.com/qatarfoundation`}
						dangerouslySetInnerHTML={{
							__html: instagram.svg
						}}
					/>
				</div>
				<div className={styles.copyrightLanguage}>
					<span className={styles.copyrightMessage}>{footerCopyrightMessage}</span>
					{children}
				</div>
			</div>
		);
	}
}

export default injectIntl(MainMenuSocialCopyright);