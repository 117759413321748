module.exports = {
	overview: 'نبذة',
	idkt: 'مكتب التطوير الصناعي ونقل المعرفة',
	idkt_long: 'مكتب التطوير الصناعي ونقل المعرفة',
	navigation: "تصفح موقعنا",
	technologies: "التقنيات",
	about: "نبذة عن عملنا",
	infobank: "بنك المعلومات",
	road_to_technology_transfer: "مسيرة نقل التكنولوجيا",	
	for_industry: "لأرباب الصناعة",
	what_we_offer: "ماذا نقدم",
	how_licensing_works: "كيف تحصل على ترخيص",
	resources_for_industry: "مصادر لأصحاب الصناعة",
	for_researchers: "للباحثين",
	how_commercialization_works: "كيف نسوق ابتكارك تجاريًا",
	resources_for_researchers: "مصادر للباحثين",
	for_entrepreneurs: "لرواد الأعمال",
	how_to_launch_a_startup: "كيف تطلق شركة ناشئة",
	resources_for_entrepreneurs: "مصادر لرواد الأعمال",

	// ecosystem
	qstp_popup_content: 'تشكل واحة قطر للعلوم والتكنولوجيا مركزًا متخصصًا في الابتكارات، وتدعم عمليات التسويق التجاري للابتكارات عبر توفير مرافق وخدمات تتمتع بمزايا المناطق الحرة لتيسير إجراءات تسجيل الشركات والاستفادة من حاضنات الأعمال والحصول على التمويل الموجه تحديدًا لشركات التكنولوجيا. ويعمل مكتبنا عن كثب مع واحة قطر للعلوم والتكنولوجيا في مشروعات ريادية متنوعة',
	"tamu-q_popup_content": 'تمتلك جامعة تكساس إي أند أم في قطر مراكز بحثية في مجال الهندسة تركز على الغاز والوقود وسلامة العمليات والشبكات الذكية والحوسبة المتطورة والحلول المستدامة للاستفادة من المياه والطاقة.',
	"vcu-q_popup_content": 'تسعى جامعة فرجينيا كومنولث كلية فنون التصميم في قطر للإسهام في حركة التنمية البشرية والاجتماعية والاقتصادية والبيئية في قطر والمنطقة من خلال تبني الإبداع في الابتكارات وأشكال التعبير وأوجه التعاون المختلفة.',
	"wcm-q_popup_content": 'تعمل وايل كورنيل للطب – قطر على تطوير قدرات مخبرية أساسية في عدد من المجالات، منها الجينوميات والبروتيوميات، والبيولوجيا الحاسوبية والإحصاءات الحيوية، والمربى الأحيائي "فيفاريوم"، والفحص المجهري، والدعم المخبري الأساسي.',
	qgp_popup_content: 'يركز برنامج قطر جينوم على تعزيز بحوث الجينوم من خلال إنشاء قواعد بيانات ضخمة تضم تسلسلًا كاملًا للجينوم وغيره من البيانات المتعلقة بعلوم "الأوميكس"، إلى جانب بيانات شاملة للنمط الظاهري.',
	qbb_popup_content: 'يُعد قطر بيوبنك مستودعًا للعينات البيولوجية والمعلومات المتعلقة بصحة المواطنين والمقيمين في دولة قطر لفترة طويلة وأساليب حياتهم. تُتيح هذه المبادرة للعلماء المحليّين إمكانية إجراء بحوث طبية حيوية حول المشكلات الصحية السائدة في دولة قطر وربط هؤلاء الباحثين بنظرائهم داخل قطر وخارجها.',
	qbri_popup_content: 'يهدف معهد قطر لبحوث الطب الحيوي إلى تحسين الرعاية الصحية وتطويرها من خلال الابتكار في الوقاية والتشخيص والعلاج من الأمراض التي تؤثر على سكان قطر والمنطقة',
	hbku_popup_content: 'جامعة حمد بن خليفة جامعة رائدة عمليًّا وتعليميًّا وبحثيًّا، ركيزتها الابتكار؛ من أجل تقديم حلول للتحديات التي تواجه دولة قطر والعالم بأسره',
	qcri_popup_content: 'يجري معهد قطر لبحوث الحوسبة بحوثًا في مجال الحوسبة المتعددة التخصصات وذات المعايير العالمية التي تتعلق باحتياجات قطر والمنطقة العربية والعالم بأسره. ويضطلع المعهد ببحوث أساسية في تلك المجالات مثل تقنيات اللغة العربية والحوسبة الاجتماعية وتحليلات البيانات والأمن السيبراني',
	qeeri_popup_content: 'يطور معهد قطر لبحوث البيئة والطاقة تقنيات مبتكرة في مجالات تحلية المياه ومعالجتها، وتحسين جودة المياه وإعادة استخدامها، وتغذية خزان المياه الجوفية، والتغير المناخي وعلوم الغلاف الجوي. كما يطور حلولًا في مجال الخلايا الشمسية الكهروضوئية وتخزين الطاقة والشبكات الذكية',

	// for-industry
	find_popup_content: 'ابحث في <a href="/ar/idkt/technologies">قاعدة بياناتنا الخاصة بالتكنولوجيات</a> عن الحلول المبتكرة أو <a href="mailto:IDKT@qf.org.qa">تواصل معنا</a> لمناقشة التحديات الفنية التي تواجهها.',
	evaluate_popup_content: "نتيح إمكانية توقيع اتفاقيات عدم إفصاح لحماية المعلومات المشمولة بحقوق الملكية وتشجيع الحوار المفتوح واستكشاف الفرص. وتسمح هذه الاتفاقيات لكلا الطرفين تقييم جدوى الشراكة المقترحة، كما تسمح لك باستخدام التكنولوجيا المقدمة من مؤسسة قطر لفترة محدودة قبل تنفيذ اتفاق لاستخراج ترخيص تجاري لها.",
	negotiate_popup_content: "سنتعاون معًا في صياغة شروط تعود بالنفع على الطرفين، والاتفاق على أهداف مرحلية قابلة للتحقيق، وذلك بناءً على استخدامك المقترح للتكنولوجيا.",
	plan_popup_content: 'نتطلع إلى التعرف على نموذج عملك وخططك لاستخدام التكنولوجيا. وفي أثناء العمل على إعداد خطتك، ضع <a data-origin="same" href="/ar/idkt/for-industry#section-3">معايير الترخيص</a> لدينا في اعتبارك. وسوف نتعاون معك لنضمن الحصول على حماية لحقوق الملكية الفكرية في مناطق قطاعات الأسواق عند توفرها.',
	sign_popup_content: "نمنح تراخيص حصرية وشبه حصرية وغير حصرية بناءً على خطة عملك. يمكننا أيضًا مساعدتك في بناء علاقات تعاونية بناءً على الملكية الفكرية الموجودة مسبقًا وقدرات الباحثين.",
	develop_popup_content: "نساعدك بالخبرات الفنية المتاحة لدينا وغيرها من وسائل نقل المعرفة خلال عملك على الحد من المخاطر وإثبات موثوقية المنتج/الخدمة وتلبية متطلبات السوق، وذلك من خلال أنشطة توسيع نطاق التطبيق والاختبار والتوثيق والتسويق.",
	promote_popup_content: "•	تعزيز النجاح: نتعاون معك في إعداد مواد دعائية مناسبة تبرز قصة نجاحك في نقل التكنولوجيا لتعزيز الجوانب المتعلقة بالعلاقات العامة.",

	// for-entrepreneurs
	study_popup_content: 'نقدم الاستشارات المتعلقة بحقوق الملكية الفكرية والمساعدة الفنية لرواد الأعمال المحليين غير المنتسبين لمؤسسة قطر من خلال <a href="https://assets.ctfassets.net/2h1qowfuxkq7/7gCW2bKIcbZ2dk4vol1eMy/d18e015003703840c5e18c5c5b466034/AlKhabeerProgram.pdf">برنامجنا الخبير</a> حيث نساعدهم في تحديد مدى جدوى أفكارهم الابتكارية. وإن كنت باحثًا في مؤسسة قطر، فيمكنك أيضًا الالتحاق ببرنامج <a href="/ar/idkt/for-researchers#section-3">زمالة الابتكار</a> السابق للتسويق التجاري الذي يهدف إلى مساعدتك في الحد من المخاطر التي قد تحيط بفرصة ريادية محتملة.',
	projections_popup_content: "توفر واحة قطر للعلوم والتكنولوجيا مجموعة واسعة من الدورات التدريبية وبرامج الدعم لمساعدة رواد الأعمال الجدد في عمليات التخطيط لإطلاق شركات ناشئة ناجحة.",
	licensing_popup_content: 'نقدم اتفاقيات ترخيص حصرية وغير حصرية لاستخدام ابتكاراتنا وفقًا لاحتياجات مشروعك أو شركتك. وتُصمم هذه الاتفاقيات خصيصًا لتناسب طبيعة المنتج أو الخدمة المحددة التي تقدمها شركتك، ويجري التفاوض بشأنها وفقًا لمعايير السوق. كما تتيح <a data-origin="same" href="/ar/idkt/for-entrepreneurs#section-3">الإجازة الريادية</a> للشركة الاستفادة من الباحثين في أنشطة استراتيجية محددة، باعتبار كل حالة على حدة.',
	establish_popup_content: "تعمل واحة قطر للعلوم والتكنولوجيا وفق نظام المنطقة الحرة، وهي مهيأة لتقديم الخدمات اللازمة لتسجيل شركتك.",
	incubation_popup_content: 'شجعك على التقديم لبرنامج تسريع تطوير المشاريع التكنولوجية "أكسيليريت" التابع لواحة قطر للعلوم والتكنولوجيا وغيره من برامج الحاضنات والتوجيه والتسويق.',
	mvp_popup_content: 'في أثناء عملك على تطوير منتجك ليصل إلى حدٍ يسمح بطرحه في السوق، ستتاح لك فرصٌ للاستفادة من المعاهد البحثية بمؤسسة قطر للحصول على خدمات استشارية واستخدام المرافق البحثية وكذلك الاستعانة بمنتجات الملكية الفكرية الأخرى التي طورتها مؤسسة قطر. <a href="mailto:IDKT@qf.org.qa">تواصل معنا</a> لمعرفة المزيد.',
	fund_popup_content: "تدبير التمويل: لاستكمال تمويل مشروعك، توفر لك واحة قطر للعلوم والتكنولوجيا فرصًا تمويلية من خلال صندوق تمويل تطوير المنتجات وصندوق تمويل المشاريع التقنية، ما يسهم في زيادة التمويل الذي قد تحصل عليه من المستثمرين المساندين ومقدمي التمويل الأولي والمستثمرين الرأسماليين.",

	// for-researchers
	research_popup_content: "فكّر وأنت تجري دراساتك وبحوثك كيف يمكن لنتائج عملك أن تلبي حاجة محددة يطلبها السوق؛ فمعرفتك بحدود إمكانات الحلول المتاحة حاليًّا في السوق يساعدك في تحسين منتجك على نحو يجعله متميزًا عن غيره ويزيد من فرص نجاح تسويقه تجاريًّا.",
	report_popup_content: 'مع بداية ظهور نتائج عملك من ابتكارات حديثة أو محسنة لعمليات أو أدوات أو تقنيات أو مواد أو برمجيات وما إلى ذلك، لا تنس تسجيلها في النظام الإلكتروني للإفصاح عن الاختراعات، حتى يتسنى لنا حماية أفكارك والشروع في مساعي تعظيم قيمتها وأثرها المرجو. استوف جميع البيانات المطلوبة قدر الإمكان، ولا تتردد في <a href="mailto:IDKT@qf.org.qa">الاتصال بنا</a> للاستفسار عما تريد.',
	assess_popup_content: "نحلل السوق والتقنيات المنافسة للوقوف على إمكانات التسويق التجاري لاختراعك وما إذا كان ينبغي تقديم طلب براءة اختراع له.",
	development_popup_content: "بينما نعمل على تحديد أفضل وسيلة ممكنة تضمن وضع ابتكارك التكنولوجي على مسار تسويق تجاري ناجح، تساندنا بأداء دور مهم يتمثل في إطلاعنا على أفكارك وشبكة علاقاتك التي تضم جهات يُحتمل الترخيص لها.",
	startup_popup_content: "نساعد مؤسسي الشركات الناشئة في التخطيط والتأسيس والحصول على تمويل لمشروعاتهم القائمة على تقنيات مؤسسة قطر.",
	marketing_popup_content: "نتواصل مع الشركات المعنية لمعرفة مدى اهتمامهم بالحصول على تراخيص تجارية للابتكارات؛ ولذا فإن مشاركتك في التواصل مع الشركات المحتمل الترخيص لها والرد على استفساراتهم يمكن أن يعزز من جهودنا التسويقية على نحو مذهل.",
	license_popup_content: "نتفاوض مع الطرف المرخص له لتحديد نطاق حقوقه في استخدام التكنولوجيا التي ابتكرتها (دون التنازل عن ملكية مؤسسة قطر) إلى جانب التزاماته المالية وغيرها من الالتزامات تجاه مؤسسة قطر.",
	commercialization_popup_content: "في الوقت الذي يعمل فيه المرخص له على تجهيز التكنولوجيا لطرحها في السوق، قد يُطلَب منك تقديم مساعدة فنية وفقًا لبنود الاتفاق التي جرى التفاوض عليها.",
	revenue_popup_content: "ستحصل على حصة من عائدات الترخيص التي ستتلقاها مؤسسة قطر، وتتشارك هذه العائدات أيضًا كيانات أخرى تسهم في الابتكار إلى جانب معاهد مؤسسة قطر ومراكزها.",
	
	other_popup_content: "تحتضن مؤسسة قطر إلى جانب ذلك نخبة من الجامعات العالمية الرائدة. تفضل بزيارة",
	goto_website: "تفضل بزيارة الموقع الإلكتروني",
	goto_higher_education: "مواقع الجامعات وبرامج التعليم العالي"
}