import * as React from 'react';

import * as styles from './MainMenuSubMenu.module.scss';
import { TimelineMax } from 'gsap/dist/gsap.min';
import { EASE_CUSTOM_IN_OUT } from '../../utils/Constants';
import GatsbyLink from 'gatsby-link';
import GatsbyLinkExternalSupport from '../../ui/GatsbyLinkExternalSupport';
import $ from 'jquery/dist/jquery.min';

export type SubMenuItem = { node?: any; title?: string; contentful_id?: string; id: string; label?: string; slug?: string; path?: string };

export class MainMenuSubMenu extends React.Component<{
	isMainMenu: boolean;
	mapActive?: boolean;
	firstMenuItems?: SubMenuItem[];
	secondaryMenuItems?: SubMenuItem[];
	firstMenuItemsClickHandler?: (event) => void;
	secondaryMenuItemsClickHandler?: (event) => void;
	mapClickHandler?: (event) => void;
	refListItemsArray: HTMLLIElement[];
	showLengthOnSecondaryItems?: boolean;
	wrapperClass?: string;
	activeIndex: number;
	closeCallback: (event) => void;
	animationDirection: 1 | -1;
}> {
	// Animation variables:
	private xItemsOut = -150;

	private refListItems: HTMLLIElement[] = [];
	private refWrapper: HTMLDivElement;
	//@ts-ignore:
	private refList: HTMLUListElement;

	private timeline: TimelineMax;

	private visible = false;

	public show = (instant = false) => {
		if (!this.visible) {
			this.visible = true;

			if (this.timeline) {
				this.timeline.kill();
				this.timeline = null;
			}

			this.timeline = new TimelineMax();
			this.timeline.set(this.refWrapper, { display: 'block' });
			this.timeline.to(this.refWrapper, 0.4, { opacity: 1, pointerEvents: 'all', force3D: true });
			this.timeline.staggerTo(this.refListItems, 0.85, { x: 0, ease: EASE_CUSTOM_IN_OUT, force3D: true }, 0.05, 0);
			this.timeline.staggerTo(this.refListItems, 0.85, { opacity: 1, force3D: true }, 0.05, 0.35);
			if (instant) {
				this.timeline.progress(1);
			}
		}
	};

	public hide = (instant = false) => {
		if (this.visible) {
			this.visible = false;

			if (this.timeline) {
				this.timeline.kill();
				this.timeline = null;
			}

			this.timeline = new TimelineMax();
			this.timeline.set(this.refWrapper, { pointerEvents: 'none' });
			// this.timeline.to(this.refListItems, 0.4, {opacity: 0, force3D: true);
			this.timeline.to(this.refWrapper, 0.3, { opacity: 0, force3D: true }, 0);
			this.timeline.set(this.refListItems, { opacity: 0, x: this.xItemsOut * this.props.animationDirection, force3D: true });
			this.timeline.set(this.refWrapper, { display: 'none' });
			if (instant) {
				this.timeline.progress(1);
			}
		}
	};

	public getWidth = () => {
		this.refWrapper.style.display = 'block';
		return this.refWrapper.clientWidth;
	};

	/*
	componentDidMount(): void {
		// TweenMax.set(this.refListItems, { opacity: 0, x: this.xItemsOut * this.props.animationDirection, force3D: true });
	}*/

	private shiftthemenufocus = event => {
		var answer = event.currentTarget;
		var parent = $(answer).parent()[0];
		var check = $(parent).next().length;
		var grandparent = $(parent)
			.parent()
			.parent()
			.parent()
			.parent();
		var before_grandparent = $(grandparent).prev();

		var theactiveparent = $(before_grandparent).children(':visible')[0];
		var activeparent = $(theactiveparent)
			.children()
			.children();
		var fjactiveparent = $(activeparent)[0];

		if (event.key == 'Enter') {
			this.props.firstMenuItemsClickHandler;
		} else if (check === 0) {
			if ($('#mapnavtopbackbtn').css('opacity') == '1') {
				window.setTimeout(function() {
					$('#mapnavtopbackbtn').focus();
				}, 300);
			} else if ($('#mapnavtopbackbtn').css('opacity') == '0') {
				$(fjactiveparent).focus();
			}

			if ($('#backbtnmainnav').css('opacity') == '1') {
				window.setTimeout(function() {
					$('#backbtnmainnav').focus();
				}, 300);
			} else if ($('#backbtnmainnav').css('opacity') == '0') {
				$(fjactiveparent).focus();
			}
		}
	};

	public shiftthemenufocus1 = event => {
		var answer = event.currentTarget;
		var parent = $(answer).parent()[0];
		var grandparent = $(parent)
			.parent()
			.parent()
			.parent()
			.parent();
		var before_grandparent = $(grandparent).prev();

		var before_grandparent_child = $(before_grandparent)
			.children()
			.children();
		var check = $(parent).next().length;

		if (event.key == 'Enter') {
			var checkifmap = $(parent).children()[0];
			var checkmapurl = $(checkifmap).attr('href');
			if (checkmapurl == '#map') {
				this.props.mapClickHandler;
			} else {
				this.props.secondaryMenuItemsClickHandler(event);
			}
		} else if (check === 0) {
			if ($('#backbtnmainnav').css('opacity') == '1') {
				window.setTimeout(function() {
					$('#backbtnmainnav').focus();
				}, 300);
			}
			if ($('#backbtnmainnav').css('display') == 'none') {
				$(before_grandparent_child).focus();
			}
		}
	};

	public render() {
		const secondaryStartIndex = this.props.firstMenuItems ? this.props.firstMenuItems.length : 0;

		return (
			<div
				ref={ref => (this.refWrapper = ref)}
				className={`${styles.wrapper} ${this.props.wrapperClass ? this.props.wrapperClass : ''} ${this.props.activeIndex > -1 ? styles.hasSelection : ''}`}
			>
				<div className={styles.menuColumnWrapper + ' ' + (this.props.isMainMenu ? styles.mainMenuWrapper : styles.subMenu)}>
					{
						<ul ref={ref => (this.refList = ref)} className={styles.subMenuList + ' ullistmenu'} tabIndex={0}>
							{this.props.firstMenuItems &&
								this.props.firstMenuItems.map((menuItem, index) => {
									let path = '';
									let label = '';
									let id = menuItem.contentful_id ? menuItem.contentful_id : '';
									let link = false;
									if (menuItem.node) {
										path = menuItem.node.path;
										label = menuItem.node.context.title;
										id = menuItem.node.context.id;
										link = menuItem.node.link;
									} else {
										// @ts-ignore
										path = menuItem.path ? menuItem.path : menuItem.filterVerticalCategory && menuItem.filterVerticalCategory.slug ? menuItem.filterVerticalCategory.slug + '/' + menuItem.slug : menuItem.slug;
										label = menuItem.label ? menuItem.label : menuItem.title;
									}
									if (path.indexOf('#') !== 0 && path.indexOf('/') !== 0) {
										path = '/' + path;
									}
									return (
										<li
											ref={ref => {
												this.props.refListItemsArray[index] = ref;
												this.refListItems[index] = ref;
											}}
											className="firstMenuItem"
											key={index}
										>
											{this.props.firstMenuItemsClickHandler && (
												<a
													data-id={id}
													data-link={link}
													className={(this.props.isMainMenu || !this.props.secondaryMenuItems) && index === this.props.activeIndex ? styles.active : ''}
													data-index={index}
													onClick={this.props.firstMenuItemsClickHandler}
													onKeyDown={this.shiftthemenufocus}
													href={path}
													tabIndex={0}
												>
													{label}
												</a>
											)}
											{!this.props.firstMenuItemsClickHandler && (
												<GatsbyLinkExternalSupport
													onClick={this.props.closeCallback}
													activeClassName={styles.currentActivePageLink}
													data-id={id}
													className={(this.props.isMainMenu || !this.props.secondaryMenuItems) && index === this.props.activeIndex ? styles.active : ''}
													data-index={index}
													to={path}
												>
													{label}
												</GatsbyLinkExternalSupport>
											)}
										</li>
									);
								})}

							{this.props.secondaryMenuItems &&
								this.props.secondaryMenuItems.map((menuItem, index) => {
									let path = menuItem.path ? menuItem.path : menuItem.slug;
									let idkt = menuItem && menuItem.id === 'idkt';
									if (path && path.indexOf('#') !== 0 && path.indexOf('/') !== 0) {
										path = '/' + path;
									}
									let isMapLink = path && path.indexOf('#map') === 0;
									return (
										<li
											key={index}
											// @ts-ignore
											className={idkt ? 'firstMenuItem' : `secondaryMenuItem${index === 0 ? ' firstSecondaryItem' : menuItem.items && menuItem.items.length === 12 && menuItem.id === 'entities' ? ' firstSecondaryItem' : ''}`}
											ref={ref => {
												this.props.refListItemsArray[secondaryStartIndex + index] = ref;
												this.refListItems[secondaryStartIndex + index] = ref;
											}}
										>
											{(isMapLink || this.props.secondaryMenuItemsClickHandler) && (
												<a
													data-index={index}
													className={(isMapLink && this.props.mapActive) || (!this.props.isMainMenu && index === this.props.activeIndex) ? styles.active : ''}
													onClick={isMapLink ? this.props.mapClickHandler : this.props.secondaryMenuItemsClickHandler}
													href={path}
													onKeyDown={this.shiftthemenufocus1}
													tabIndex={0}
												>
													{menuItem.title ? menuItem.title : menuItem.label}
													{!idkt && this.props.showLengthOnSecondaryItems === true ? ` (${(this.props.secondaryMenuItems[index] as any).items.length})` : ''}
												</a>
											)}
											{!this.props.secondaryMenuItemsClickHandler && !isMapLink && (
												<GatsbyLink
													onClick={this.props.closeCallback}
													activeClassName={styles.currentActivePageLink}
													data-index={index}
													className={!this.props.isMainMenu && index === this.props.activeIndex ? styles.active : ''}
													to={path}
												>
													{menuItem.title ? menuItem.title : menuItem.label}
													{!idkt && this.props.showLengthOnSecondaryItems === true ? ` (${(this.props.secondaryMenuItems[index] as any).items.length})` : ''}
												</GatsbyLink>
											)}
										</li>
									);
								})}
						</ul>
					}
				</div>
			</div>
		);
	}
}
