// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-page-article-tag-items-tsx": () => import("./../../../src/templates/PageArticleTagItems.tsx" /* webpackChunkName: "component---src-templates-page-article-tag-items-tsx" */),
  "component---src-templates-page-article-tsx": () => import("./../../../src/templates/PageArticle.tsx" /* webpackChunkName: "component---src-templates-page-article-tsx" */),
  "component---src-templates-page-article-type-items-tsx": () => import("./../../../src/templates/PageArticleTypeItems.tsx" /* webpackChunkName: "component---src-templates-page-article-type-items-tsx" */),
  "component---src-templates-page-campaign-tsx": () => import("./../../../src/templates/PageCampaign.tsx" /* webpackChunkName: "component---src-templates-page-campaign-tsx" */),
  "component---src-templates-page-entity-tsx": () => import("./../../../src/templates/PageEntity.tsx" /* webpackChunkName: "component---src-templates-page-entity-tsx" */),
  "component---src-templates-page-event-tsx": () => import("./../../../src/templates/PageEvent.tsx" /* webpackChunkName: "component---src-templates-page-event-tsx" */),
  "component---src-templates-page-expert-profile-tsx": () => import("./../../../src/templates/PageExpertProfile.tsx" /* webpackChunkName: "component---src-templates-page-expert-profile-tsx" */),
  "component---src-templates-page-media-gallery-tsx": () => import("./../../../src/templates/PageMediaGallery.tsx" /* webpackChunkName: "component---src-templates-page-media-gallery-tsx" */),
  "component---src-templates-page-news-and-stories-tsx": () => import("./../../../src/templates/PageNewsAndStories.tsx" /* webpackChunkName: "component---src-templates-page-news-and-stories-tsx" */),
  "component---src-templates-page-overview-tsx": () => import("./../../../src/templates/PageOverview.tsx" /* webpackChunkName: "component---src-templates-page-overview-tsx" */),
  "component---src-templates-page-persona-tsx": () => import("./../../../src/templates/PagePersona.tsx" /* webpackChunkName: "component---src-templates-page-persona-tsx" */),
  "component---src-templates-page-place-tsx": () => import("./../../../src/templates/PagePlace.tsx" /* webpackChunkName: "component---src-templates-page-place-tsx" */),
  "component---src-templates-page-press-release-tsx": () => import("./../../../src/templates/PagePressRelease.tsx" /* webpackChunkName: "component---src-templates-page-press-release-tsx" */),
  "component---src-templates-page-program-tsx": () => import("./../../../src/templates/PageProgram.tsx" /* webpackChunkName: "component---src-templates-page-program-tsx" */),
  "component---src-templates-page-search-tsx": () => import("./../../../src/templates/PageSearch.tsx" /* webpackChunkName: "component---src-templates-page-search-tsx" */),
  "component---src-templates-page-vertical-tsx": () => import("./../../../src/templates/PageVertical.tsx" /* webpackChunkName: "component---src-templates-page-vertical-tsx" */)
}

