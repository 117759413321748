import * as React from 'react';
import { WrappedComponentProps } from 'react-intl';
import * as styles from './Hamburger.module.scss';

import $ from 'jquery/dist/jquery.min';

function shiftfocus(event) {
	var answer = event.currentTarget
	var thesolution = $(answer).parent().prev().children(2).children(0).children(2).children(0).children(0).children(2).children(0).children(0)[0]
	window.setTimeout(function () {
		$(thesolution).focus();	
	}, 300); 
};


const Hamburger = (props: { toggleHandler: (event) => void, intl?: WrappedComponentProps }) => (
	// @ts-ignore
	<a onKeyDown={shiftfocus} onClick={props.toggleHandler} href="#menu-open" className={styles.wrapper}>
		<div className={styles.line} />
		<div className={styles.line} />
		<div className={styles.line} />
	</a>
);

export default Hamburger;
