module.exports = {
	...require("./IDKT/ar.js"),
	selectLanguage: 'اختر لغتك',
	'email:': 'البريد الإلكتروني:',
	email: 'البريد الإلكتروني',
	'tel:': 'رقم الهاتف:',
	Sections: 'الأقسام',
	Navigation: 'مؤسسة قطر',
	Education: 'التعليم',
	Research: 'البحوث',
	Community: 'المجتمع',
	All: 'الكل',
	Home: 'الصفحة الرئيسية',
	event: 'الفعاليات',
	Article: 'قصة',
	'Related Articles': 'مواد ذات الصلة',
	place: 'الأماكن',
	entity: 'الكيانات',
	program: 'البرامج',
	people: 'الأعضاء',
	page: 'الصفحات',
	article: 'قصة ذات صلة',
	Story: 'قصة ذات صلة',
	university: 'الجامعات',
	school: 'المدارس',
	press_release: 'بيانات صحفية',
	singular_place: 'مكان',
	singular_entity: 'جهة',
	singular_program: 'برنامج',
	singular_people: 'عضو',
	singular_university: 'جامعة',
	singular_school: 'مدرسة',
	singular_page: 'صفحة',
	singular_article: 'قصة',
	singular_event: 'فعالية؛',
	singular_press_release: 'بيانات صحفية',
	plural_place: 'الأماكن',
	plural_event: 'الفعاليات',
	plural_entity: 'الكيانات',
	plural_program: 'البرامج',
	plural_people: 'الأشخاص',
	plural_campaign: 'الحملات',
	plural_university: 'الجامعات',
	plural_school: 'المدارس',
	plural_page: 'الصفحات',
	plural_article: 'القصص',
	plural_press_release: 'البيانات الصحفية',
	plural_press_media_mention: 'ما كُتب عن المؤسسة',
	search_filter_school: 'مدارس',
	search_filter_university: 'جامعات',
	search_filter_program: 'برامج',
	search_filter_place: 'أماكن',
	search_filter_entity: 'كيانات',
	search_filter_article: 'قصص',
	search_filter_event: 'فعاليات',
	search_filter_people: 'أعضاء',
	search_filter_page: 'صفحات',
	search_filter_press_release: 'بيانات صحفية',
	search_filter_press_media_mention: 'ما كُتب عن المؤسسة',
	search_filter_list_entity: 'كيان',
	search_filter_list_place: 'مكان',
	search_filter_list_type: 'نوع',
	search_filter_list_part_of_qatar: 'عضو في مؤسسة قطر',
	search_filter_list_department: 'قسم',
	search_filter_list_category: 'فئة',
	search_filter_list_tags: 'وسم',
	search_filter_list_month: 'شهر',
	search_filter_list_year: 'سنة',
	search_filter_list_campaign: 'حملة',
	search_filter_list_program: 'برنامج',
	programs_filter_list_schools: 'تصنيف حسب المدرسة',
	programs_filter_list_degrees: 'تصنيف حسب الدرجة الأكاديمية',
	search_category_article: 'قصص',
	search_category_event: 'فعاليات',
	'Program & School': 'برنامج ومدرسة',
	Degree: 'الدرجة الأكاديمية',
	Filters: 'تصنيفات',
	Search: 'بحث',
	Map: 'خريطة',
	'See on map': 'انظر الخريطة',
	Back: 'العودة',
	List: 'قائمة',
	Fax: 'رقم الفاكس',
	Phone: 'رقم الهاتف',
	'E-mail': 'البريد الإلكتروني',
	Address: 'العنوان',
	'Offered at': 'متاح في',
	'Go to website': 'اذهب إلى الموقع الإلكتروني',
	opening_hours: 'أوقات العمل',
	Share: 'مشاركة',
	'Click here to register': 'اضغط هنا للتسجيل',
	'Click here to join the seminar': 'اضغط هنا لمشاهدة المحاضرة',
	'Related Event(s)': 'فعاليات ذات صلة',
	'Featured Event(s)': 'أبرز الفعاليات',
	'No results': 'لا توجد نتائج.',
	'search.result.plural': 'تم العثور على {value} نتيجة/نتائج',
	'search.result.singular': 'وجدت نتيجة واحدة',
	'search.result.spellingsuggestion': 'هل قصدت {link}؟',
	messageNoSearchStarted: 'لاتوجد نتائج، جرّب استعلام بحث أو اختر معيار آخر للبحث.',
	Date: 'التاريخ',
	Time: 'الوقت',
	Where: 'المكان/أين',
	'Retry this query with advanced search': 'ابحث مرة أخرى مع إعدادات البحث المتقدم؟',
	'See all results': 'إظهار جميع النتائج',
	'Organized by': 'الجهة المنظمة',
	path_entity_base: '/entity/', // Check this
	'Browse by Category': 'تصفح بحسب النوع',
	'Browse all Events': 'تصفح جميع الفعاليات',
	'Browse all Media Mentions': 'تصفّح ما كُتب عن المؤسسة',
	'Interested in more events?': 'مهتم بمزيد من الفعاليات؟',
	'page.link': 'تعرف على المزيد',
	'location.get_directions': 'تعرف على كيفية الوصول',
	in: ' | ',
	Type: 'نوع',
	Schools: 'مدارس',
	Universities: 'جامعات',
	Programs: 'برامج',
	Entities: 'كيانات',
	Places: 'أماكن',
	'image.pagination': 'صورة {index} من {total}',
	'scrolllist.pagination': '{index} من {total}',
	'Q.': 'سؤال:',
	'A.': 'جواب:',
	'Press Releases': 'البيانات الصحفية',
	'Other Media': 'مؤسسة قطر حول العالم',
	cookie_title: 'هذا الموقع يستخدم ملفات تعريف الارتباط (كوكيز).',
	cookie_content: 'نستخدم في هذا الموقع ملفات تعريف الارتباط (كوكيز) لنتمكن من تلبية احتياجات زوار الموقع وتحسين تجربة التصفح.',
	'cookie.link': 'تعرف على المزيد',
	'general-inquiries-footer-text': 'للاستفسارات الإعلامية بإمكانكم\n التواصل معنا عبر',
	'balagh-qf-hotline-footer-text': 'هل تريد منع الاحتيال أو الإبلاغ عن حالات الاحتيال؟\n تواصل معنا على',
	'Anti-Fraud and Whistle-Blowing Hotline': 'الخط الساخن لمكافحة الاحتيال والإبلاغ عن المخالفات',
	SearchHelpText: 'البحث في موقع مؤسسة قطر',
	mmsctwtarialabel: 'صفحة مؤسسة قطر على تويتر',
	mmscfbarialabel: 'صفحة مؤسسة قطر على فيسبوك',
	mmsclinkedinarialabel: 'صفحة مؤسسة قطر على لينكد إن',
	mmscsnapchatarialabel: 'صفحة مؤسسة قطر على سنابتشات',
	mmscinstaarialabel: 'صفحة مؤسسة قطر على إنستغرام',
	mmsctwttitle: 'صفحة مؤسسة قطر على تويتر',
	mmscfbtitle: 'صفحة مؤسسة قطر على فيسبوك',
	mmsclinkedintitle: 'صفحة مؤسسة قطر على لينكد إن',
	mmscsnapchattitle: 'صفحة مؤسسة قطر على سنابتشات',
	mmscinstatitle: 'صفحة مؤسسة قطر على إنستغرام',
	imggallerymodnavprevtitle: 'الصورة السابقة',
	imggallerymodnavprevarialabel: 'الصورة السابقة',
	imggallerymodnavnexttitle: 'الصورة التالية',
	imggallerymodnavnextarialabel: 'الصورة التالية',
	sidescrollerstorylistmodnavprevtitle: 'الصورة السابقة',
	sidescrollerstorylistmodnavprevarialabel: 'الصورة السابقة',
	sidescrollerstorylistmodnavnexttitle: 'الصورة التالية',
	sidescrollerstorylistmodnavnextarialabel: 'الصورة التالية',
	scrolllistmodnavprevtitle: ' السابق',
	scrolllistmodnavprevarialabel: ' السابق',
	scrolllistmodnavnexttitle: ' التالي',
	scrolllistmodnavnextarialabel: ' التالي',
	closecookiedialogueboxclosebtntitle: 'إغلاق نافذة ملف تعريف الارتباط',
	closecookiedialogueboxclosebtnarialabel: 'إغلاق نافذة ملف تعريف الارتباط',
	Gototopbuttontitle: 'زر التوجه لأعلى الصفحة',
	Gototopbuttonarialabel: 'زر التوجه لأعلى الصفحة',
	openmenutitle: 'فتح القائمة',
	openmenuarialabel: 'فتح القائمة',
	headerlogotitle: 'الصفحة الرئيسية لمؤسسة قطر',
	headerlogoarialabel: 'الصفحة الرئيسية لمؤسسة قطر',
	QFlogomenubartitle: 'شعار مؤسسة قطر',
	QFlogomenubararialabel: 'شعار مؤسسة قطر',
	closemenutitle: 'إغلاق القائمة',
	closemenuarialabel: 'إغلاق القائمة',
	searchicontitle: 'البحث في موقع مؤسسة قطر',
	searchiconarialabel: 'البحث في موقع مؤسسة قطر',
	searchinputtitle: 'أدخل كلمة البحث',
	alert_title: 'مستجدات فيروس كورونا (كوفيد-19)',
	alert_content: 'للاطلاع على آخر المعلومات والمستجدات من مؤسسة قطر حول فيروس كورونا، يرجى زيارة ',
	'alert.link': 'صفحة التصريحات الخاصة بمؤسسة قطر',
	education_teaser: 'استكشف جامعاتنا ومدارسنا',
	education_link: 'استكشف فرص التعليم في مؤسسة قطر',
	research_teaser: 'تعرّف على مبادراتنا البحثية',
	research_link: 'استكشف البحوث في مؤسسة قطر',
	community_teaser: 'استكشف برامجنا في مجال تنمية المجتمع',
	community_link: 'استكشف مجتمع مؤسسة قطر',
	education_city_title: 'هذه المدينة التعليمية.',
	education_city_content:
		'المدينة التعليمية، أبرز مبادرات مؤسسة قطر، مكان فريد من نوعه. فرحلة قصيرة واحدة حول أرجائها، ماشيًا كنت أو راكبًا في إحدى عربات الترام، تكفي لزيارة عدد لا بأس به من الجامعات العالمية المرموقة. وإذا عبرت الشارع، ستطالعك واحدة من أكبر المكتبات في المنطقة، وبعدها يمكنك أن تشارك في نقاش مفتوح في رحاب جامعة تقبع خلف المكتبة.',
	education_city_link: 'تعرف على المزيد',
	latest_news_title: 'آخر الأخبار',
	latest_news_content: 'في مؤسسة قطر، هناك العديد من الفعاليات على مدار السنة. إليك آخر أخبارنا.',
	latest_news_link: 'تصفح جميع الأخبار',
	latest_stories_link: 'تصفح جميع القصص',
	More: 'المزيد من القصص',
	stay_in_touch: 'لنكون دائمًا على اتصال',
	follow_us_text: 'هل تودّ مشاهدة وقراءة المزيد عن مؤسستنا؟ إن قنوات التواصل الاجتماعي لدينا ستبقيك على اطلاع دائم بأحدث الأخبار.',
	sign_up_newsletter: 'هل أنت مهتم بباقة أخبارنا وفعالياتنا الشهرية؟ اشترك في نشرتنا الإخبارية.',
	sign_up_newsletter_exists: 'لقد اشتركت بالفعل في نشرتنا الإخبارية الشهرية',
	sign_up_newsletter_success: 'نشكرك على الاشتراك',
	sign_up_newsletter_invalid: 'البريد الإلكتروني غير صالح',
	sign_up_newsletter_error: 'حدث خطأ. إعداد إشعار الدفع',
	email_validation: "الرجاء إدخال عنوان البريد الإلكتروني",
	qr_campaign_title: 'قصص لا تقرأ أبدا \n قصص منسية.',
	qr_campaign_text: 'ما لا ُيقرأ يذهب طي النسيان. \n لا تدع إمكاناتك تتلاشى.\n اقرأ، وارسم مستقبلًا طالما تمنيته.',
	qr_campaign_link: 'تعرف على المزيد عن حملة "قطر تقرأ"',
	qr_goto_qf_site_link: 'للمتابعة لموقع مؤسسة قطر',
	'Browse Events by Location': 'استعرض الأنشطة بحسب أماكن انعقادها',
	closealertdialogueboxclosebtntitle: 'Close The Alert Dialogue Box',
	closealertdialogueboxclosebtnarialabel: 'Close The Alert Dialogue Box',
	darkmode: 'الوضع المعتم',
	lightmode: 'الوضع العادي',
	push_success: 'تم تفعيل خاصية الإشعارات بنجاح',
	push_enable_error: 'حدث خطأ أثناء إعداد إشعار الدفع ، يرجى المحاولة مرة أخرى!',
	push_not_allowed: 'خاصية الإشعارات غير مسموح بها',
	push_disabled: 'خاصية الإشعارات متوقفة',
	push_disable_error: 'حدث خطأ أثناء تعطيل خاصية الإشعارات. يرجى المحاولة مرة أخرى',
	push_generic_error: 'حدث خطأ أثناء إعداد إشعار الدفع',
	name: "الاسم الكامل",
    email: "البريد الإلكتروني",
    title: "الاسم",
	company: "الجهة",
	nationality: "الجنسية",
    country: "بلد الإقامة",
	name_validation: "الرجاء إدخال الاسم",
    email_validation: "الرجاء إدخال عنوان البريد الإلكتروني",
    title_validation: "الرجاء إدخال المنصب الوظيفي الحالي",
    company_validation: "الرجاء إدخال اسم الشركة",
	select_validation: "الرجاء تحديد خيار",
	subscribe_to_newsletter: "أرغب بالاشتراك في نشرة مؤسسة قطر الشهرية",
	download_report: "تحميل التقرير",
	form_submit_thankyou: "نشكركم على استكمال النموذج",
	download_report_text: "اضغط هنا لتحميل التقرير",
	mada_text: 'هذا الموقع قابل للنفاذ ومعتمد من قبل مدى',
	mada_logo: 'شعار مدى',
	search_filter_spokes_people: 'معلقون وخبراء',
	singular_spokes_people: 'معلقون وخبراء',
	plural_spokes_people: 'معلقون وخبراء',
	"How did you hear about the event?": "كيف سمعت عن الجلسة النقاشية؟",

	form_register_thankyou: "شكرًا على التسجيل للجلسة.",
	webinar_link: "رابط الجلسة النقاشية",
	add_to_calendar: "أضف إلى التقويم",
	register: "تسجيل",
	registering: "التسجيل...",
	"Register to attend": "تسجيل المشاركة",
	webinar_note: "قريبًا سنرسل لك بريدًا إلكترونيًا بالتفاصيل الكاملة لكيفية مشاهدة الجلسة النقاشية.",

	ecss_title: "سلسلة محاضرات المدينة التعليمية",
	ecss_subtitle: "تتحدث ليلى سعد، مؤلفة صاحبة كتاب أعلى مبيعًا، عن تجربتها الشخصية مع العنصرية، ومسؤوليتنا الاجتماعية لاتخاذ إجراءات تستهدف رصد الممارسات العنصرية ونبذها من مجتمعاتنا.",
	join_webinar: "انضم إلى الجلسة النقاشية.",
	phone: "الهاتف",
	title_of_the_artwork: "عنوان العمل الفني",
	artists_statement: "بيان الفنان (100 كلمة كحد أقصى)",
	artists_statement_info: "سيعرض هذا البيان مع العمل على الموقع الإلكتروني للمعرض الفني. وهو وصف مختصر حول تجربتك الفنية وتفسير لاختياراتك الفنية. لا يجب أن تكون التفسيرات حرفية أو قطعية، بل ينبغي أن تكون دعوة للمشاهد للتعمق في العمل، مع مراعاة سياق موضوع المعرض.",
	artists_biography: "السيرة الذاتية للفنان (100 كلمة كحد أقصى)",
	artists_biography_info: "وصف موجز عن مسيرتك الفنية والمهنية. سيُعرض هذا المحتوى بجوار عملك الفني على الموقع الإلكتروني للمعرض.",
	artwork_dimensions: "أبعاد العمل الفني",
	artwork_dimensions_info: "• العرض والارتفاع والعمق (إن وجد)\n• في حالة الأعمال الفنية غير التقليدية، يرجى تقديم البعد التقريبي للحجم\n• إذا كان العمل على شكل فيديو، يرجى ذكر مدته",
	artwork_material: "المواد المستخدمة في العمل الفني",
	artwork_material_info: "وصف واضح للمواد التي استخدمت لإنتاج العمل الفني",
	artwork_image: "عمل فني مصوّر",
	artwork_image_info: "إذا كانت مشاركتك على شكل عمل فني رقمي متحرك، أو فيديو، أو أداء تمثيلي، يرجى إرسال رابط التسجيل الكامل هنا.\n\nأنواع الملفات: zip. أو .jpg أو jpeg. أو png. أو .pdf\nحجم الملف: 10 ميجابايت كحد أقصى (يقبل ملف واحد من كل مشارك)",
	artwork_link: "رابط العمل الفني",
	artwork_link_info: "إذا كانت مشاركتك على شكل عمل فني رقمي متحرك، أو فيديو، أو أداء تمثيلي، يرجى إرسال رابط التسجيل الكامل هنا.",
	artwork_copyrights_info: "يقدم كل مشارك عملًا فنيًا واحدًا. \n\nسيظل العمل الفني في ملكية الفنان. ولكن في حال تقديم العمل الفني، يمكن استخدام المشاركات الإلكترونية بواسطة مؤسسة قطر وشركائها، ويحق لها نشرها للعامة، بما في ذلك أي معلومات تعريفية أخرى تقدمها، كالصور وغيرها، في إطار المسابقة الفنية.",
	artwork_terms_conditions: "الأحكام والشروط",
	artwork_terms_conditions_info: "من خلال تقديم العمل الفني عبر الإنترنت، يتعهد الفنان بالتالي:",
	artwork_terms_conditions_info_1: "أصالة العمل الفني وإنجازه من قبل الفنان نفسه",
	artwork_terms_conditions_info_2: "أن الفنان هو المالك الوحيد والمطلق للعمل، وأن العمل لا يخضع بأي شكل من الأشكال للحقوق أو المطالبات أو الامتيازات أو الرهون أو الفوائد الأمنية أو غيرها من الرهونات تجاه أي شخص.",
	artwork_terms_conditions_info_3: "عدم انتهاك العمل لحقوق أي شخص أو كيان، بما في ذلك العلامات التجارية وحقوق النشر والخصوصية وحقوق الدعاية.",
	artwork_terms_conditions_info_5: "يحق لمؤسسة قطر ما يلي:",
	artwork_terms_conditions_info_6: "مراجعة كل مشاركة قبل عرضها على الموقع الإلكتروني",
	artwork_terms_conditions_info_7: "نشر ومشاركة وتوزيع الأعمال الفنية للمشاركين",
	artwork_terms_conditions_info_8: "إعادة إنتاج الأعمال الفنية عبر الإنترنت أو طباعتها، بما في ذلك، على سبيل المثال لا الحصر، عبر موقعها الإلكتروني، ومنصات التواصل الاجتماعي، وغيرها من صفحات الإنترنت، وذلك بهدف الترويج للمعرض أو لأغراض الأرشفة.",
	artwork_terms_conditions_info_9: "يحق لمؤسسة قطر رفض التالي:",
	artwork_terms_conditions_info_10: "التقديمات غير المكتملة",
	artwork_terms_conditions_info_11: "التقديمات التي لا تعكس الغرض من المعرض",
	artwork_terms_conditions_agree: "أوافق على الأحكام والشروط",
	upload_placeholder: "إضافة تفاصيل",
	required_field: "هذه الخانة مطلوبه",
	invalid_phone: "الرجاء إدخال رقم الجوال",
	invalid_link: "أدخل رابط صحيح من فضلك",
	submit: "أرسل",
	submiting: "تقديم...",
	file_size_limit_exceeded: "(1MB أقصى) .تم تجاوز حد حجم الملف",
	ppf_form_thankyou: "!شكرًا لإرسال مشاركتك",
	first_name: "الاسم الأول",
	last_name: "اسم العائلة",
	qid: "رقم بطاقة الهوية القطرية للطالب",
	dob: "تاريخ الميلاد",
	gender: "النوع",
	academic_gpa: "المعدل التراكمي الأكاديمي",
	upload_current_transcript: "حمّل كشف العلامات الحالي",
	current_school: "المدرسة الحالية",
	student_name: "اسم الطالب",
	student_phone: "رقم هاتف الطالب",
	student_email: "البريد الإلكتروني للطالب",
	parent_phone: "رقم هاتف ولي الأمر",
	parent_email: "البريد الإلكتروني لولي الأمر",
	confidential_references: "رسائل تزكية سرية",
	confidential_references_text: "يُرجى إرسال أسئلة التزكيات أدناه إلى اثنين من المعلمين الحاليين وواحد من كبار المدراء في المدرسة. وحتى تكون الطلبات صالحة للمراجعة، يجب أن تكون التزكيات على أوراق المدرسة الرسمية وأن تُرسل على البريد الإلكتروني لمدير القبول، فيكي رايت (vwright@qf.org.qa) في موعد أقصاه يوم الأحد 22 نوفمبر 2020. يُرجى العلم بأن رسائل التزكية سرية ولا ينبغي أن يشاركها أو يطلع عليها أولياء الأمور و/أو الطلاب.",
	recommendatio_questions_heading: "أسئلة التزكيات:",
	recommendatio_question_1: "منذ متى تعرف هذا الطالب؟",
	recommendatio_question_2: "يُرجى توضيح سبب توصيتك بهذا الطالب للجنة الاختيار؟",
	recommendatio_question_3: "ماذا سيحضر هذا الطالب إلى مجتمع مؤسسة قطر؟",
	recommendatio_question_4: "هل لديك أية تحفظات على شخصية هذا الطالب وقدراته الأكاديمية؟",
	recommendatio_question_5: "هل لديك تعليقات إضافية ينبغي أن نعرفها عن هذا الطالب؟",
	essay_topic: "مقال من 500 كلمة",
	essay_topic_question: "لماذا ترغب في الانضمام إلى إحدى مدارس مؤسسة قطر وكيف ستسهم في مجتمعنا؟",
	essay_topic_text: "المقال في أغلب الأحيان يكون الجزء الأكثر أهمية من طلبك؛ فهو يعطي لجنة المنح الدراسية انطباعًا عنك وعن تفانيك لتحقيق أهدافك، لذلك عليك أن تحرص على أن يكون في أفضل صورة ممكنة.",
	essays_formatting_heading: "تنسيق مقالات المنح الدراسية:",
	essays_format_1: "تباعد مزدوج",
	essays_format_2: "خط Times New Roman",
	essays_format_3: "حجم الخط 12",
	essays_format_4: "هوامش بطول إنش واحد من الأعلى والأسفل والجوانب",
	other_useful_tips_heading: "نصائح مفيدة أخرى ينبغي الانتباه لها:",
	other_useful_tip_1: "فكر فيما ستقول ونظم أفكارك عند كتابتها في مخطط عام",
	other_useful_tip_2: "احرص خلال كتابة المقال على توضيح كل فكرة ضمنتها في المخطط العام",
	other_useful_tip_3: "استخدم في مقالك لغة واضحة وموجزة وبسيطة",
	other_useful_tip_4: "تأكد من تدقيق وتحرير مقالك",
	other_useful_tip_5: "حمّل المقال المكتمل",
	i_would_like_to_apply_to: "أود أن أتقدم بطلب إلى: يُرجى اختيار ثلاث مدارس مختلفة في مؤسسة قطر",
	first_choice_school: "الخيار الأول للمدرسة",
	second_choice_school: "الخيار الثاني للمدرسة",
	third_choice_school: "الخيار الثالث للمدرسة",
	scholarship_terms: 'أوافق على أنني قرأت المعلومات المذكورة أعلاه. وأدرك أن هذه هي الخطوة الأولى التي سيتم النظر فيها للحصول على "منحة الجدارة للتعليم ما قبل الجامعي"، وأنه سيتم إخطاري عبر البريد الإلكتروني في 13 ديسمبر 2020 إذا تم اختياري للانتقال إلى الخطوة التالية، وسيتم أيضًا إبلاغ الطلاب الذين لم يتم اختيارهم عبر البريد الإلكتروني.',
	date_placeholder: "شهر / يوم / سنة",
	upload_recommendations: "إرسال خطابات التوصية",
	upload_recommendations1: "(خطاب التوصية 1 (معلم حالي",
	upload_recommendations2: "(خطاب التوصية 2 (معلم حالي",
	upload_recommendations3: "(خطاب التوصية 3 (موظف إداري رفيع المستوى",
	qf_alumni_portal_launch_signup_heading: "سنطلق بوابة خريجي مؤسسة قطر في الأشهر القادمة.",
	qf_alumni_portal_launch_signup_text: "أدخل اسمك وعنوان بريدك الإلكتروني، وسنتواصل معك فور إطلاق البوابة الإلكترونية!",
	notify_me: "تواصل معي",
	ppf_form_thankyou: "!شكرًا لإرسال مشاركتك",
	qf_brand_audit_heading: "نود معرفة تصوراتك حول مؤسسة قطر",
	qf_brand_audit_content: "هل يمكن أن تمنحنا خمس دقائق من وقتك وتكمل هذا الاستبيان القصير؟",
	qf_brand_audit_cta: "أكمل الاستبيان",
	"Register on the Education City App": "سجل من خلال تطبيق المدينة التعليمية",
	youthOfChange_intro: "تعرّف على بعض طلبة التغيير الشغوفين من مدارسنا وجامعاتنا المختلفة.",
	innovatorsOfChange_intro: "تعرَّف على بعض باحثينا وأفراد مجتمعنا ورحلتهم ضمن مسيرة التغيير.",
	partnersOfChange_intro: "تعرف على بعض شراكاتنا التي تمكّن طلبة التغيير.",
	read_more: "اقرأ المزيد",
	read_less: "اقرأ أقل",
	download_full_gallery: "تنزيل جميع الصور",
	all_galleries: "الصور والفيديوهات",
	publications_projects : "مشاريع ومنشورات",
	affiliation: 'الجهة التي ينتسبون إليها',
	languages: 'اللغات',
	publications: 'منشورات',
	projects: 'مشروعات',
	book_interview: 'مقابلة حول كتاب',
	book_interview_link: 'mailto:pressoffice@qf.org.qa?subject=Book%20interview%20with%20%3Ctitle%3E',
	editors_picks: 'مختارات',
	select_dropdown: 'تحديد',
	soc_survey_popup_heading: "ما أفكارك حول حملة طلبة العلم والتغيير؟",
	soc_survey_popup_cta: "شاركنا رأيك؛ لن يستغرق الاستبيان أكثر من خمس دقائق ولن تطلب منك مشاركة أي معلومات شخصية."
};
