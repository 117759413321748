import * as React from 'react';
import Link from 'gatsby-link';
import SelectLanguage from './SelectLanguage';

import * as styles from './Header.module.scss';
import Hamburger from './Hamburger';
import Search from './Search/Search';
import { globalsQueryAndLayout_entities, globalsQueryAndLayout_places, globalsQueryAndLayout_programs, MainMenuFragment } from '../gatsby-queries';
import { UIBreadcrumb } from '../ui/UIBreadcrumb';
import { injectIntl } from 'react-intl';
import { MainMenu } from './MainMenu/MainMenu';
import { WrappedComponentProps } from 'react-intl';
import UIAlert from '../ui/UIAlert';
import DarkModeToggle from '../ui/DarkModeToggle';

const initialState = { menuOpen: false };
type State = Readonly<typeof initialState>;

class Header extends React.Component<
	{
		programs: globalsQueryAndLayout_programs;
		places: globalsQueryAndLayout_places;
		entities: globalsQueryAndLayout_entities;
		menuData: MainMenuFragment[];
		langs: any[];
		footerCopyrightMessage: string;
		alternateURL: string;
		currPageTitle: string;
		pageTitle?: string;
		location: any;
		bilingual?: boolean;
	} & WrappedComponentProps,
	State
> {
	readonly state: State = initialState;
	private refMenu: MainMenu;

	constructor(props) {
		super(props);

		if (typeof window !== 'undefined') {
			window.addEventListener('hashchange', this.hashChangeHandler);
		}
	}

	componentDidMount(): void {
		setTimeout(this.hashChangeHandler, 0);
	}

	private hashChangeHandler = () => {
		if (window.location.hash && window.location.hash.indexOf('#map') === 0) {
			if (!this.state.menuOpen) {
				this.refMenu.instantOpen = true;
				this.setState({ menuOpen: true });
			}
			setTimeout(() => {
				this.refMenu.openMap();
			}, 0);
		}
	};

	private closeHandler = event => {
		if (event) {
			const href = event.target.getAttribute('href');
			if (href && href.indexOf('#') === 0) {
				event.preventDefault();
			}
		}
		if (this.state.menuOpen) {
			this.setState({ menuOpen: false });
		}
	};

	private toggleHandler = event => {
		if (event) {
			event.preventDefault();
		}
		this.setState({ menuOpen: !this.state.menuOpen });
	};

	render() {
		const currLanguage = this.props.langs[1].selected ? this.props.langs[1] : this.props.langs[0];
		return (
			<div className={styles.wrapper}>
				<div className={styles.background} id="background" />
				<MainMenu
					ref={ref => (this.refMenu = ref)}
					programs={this.props.programs}
					places={this.props.places}
					entities={this.props.entities}
					closeHandler={this.closeHandler}
					open={this.state.menuOpen}
					footerCopyrightMessage={this.props.footerCopyrightMessage}
					currLanguage={currLanguage}
					data={this.props.menuData}
					intl={this.props.intl}
					id="mainmenu"
				>
					<SelectLanguage
						isSearch={this.props.currPageTitle === 'Search' || this.props.currPageTitle === 'بحث' ? true : false}
						inMenu={true}
						alternateURL={this.props.alternateURL}
						langs={this.props.langs}
					/>
				</MainMenu>
				<div className={styles.fixedWrapper} id="fixedWrapper">
					<Hamburger toggleHandler={this.toggleHandler} />
					<Link
						title={this.props.intl.formatMessage({ id: 'headerlogotitle' })}
						tabIndex={0}
						aria-label={this.props.intl.formatMessage({ id: 'headerlogoarialabel' })}
						className={styles.logo}
						to={this.props.langs[1].selected ? '/ar' : '/'}
					/>
					<DarkModeToggle />
					<UIBreadcrumb location={this.props.location} currLanguage={currLanguage} currPageTitle={this.props.pageTitle ? this.props.pageTitle : this.props.currPageTitle} />
					{this.props.bilingual !== false && (
						<div className={styles.languageToggleWrapper}>
							<SelectLanguage
								isSearch={this.props.currPageTitle === 'Search' || this.props.currPageTitle === 'بحث' ? true : false}
								inMenu={false}
								alternateURL={this.props.alternateURL}
								langs={this.props.langs}
							/>
						</div>
					)}
				</div>
				<Search currLanguage={currLanguage} id="currLanguage" />
				{
					// @ts-ignore
					<UIAlert showAlert={this.props.showStatementNotification} currLanguage={currLanguage.langKey} pageTitle={this.props.pageTitle}></UIAlert>
				}
			</div>
		);
	}
}

export default injectIntl(Header);
