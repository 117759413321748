import * as React from 'react';

import * as styles from './Search.module.scss';
import { TweenMax } from 'gsap/dist/gsap.min';
import { navigate } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import { EASE_CUSTOM_IN_OUT } from '../../utils/Constants';
import { injectIntl } from 'react-intl';
import { WrappedComponentProps } from 'react-intl';
import { Globals } from '../../utils/Globals';
import $ from 'jquery/dist/jquery.min';

if (typeof window !== 'undefined') {
	//@ts-ignore:
	window.$ = window.jQuery = require('jquery/dist/jquery.min');
	//@ts-ignore:
	const SwifTypeAutocomplete = require('../../lib/swiftype/jquery.swiftype.autocomplete.js');
}
//@ts-ignore:
const SwifTypeAutoCompleteCSS = require('../../lib/swiftype/autocomplete.css');

const initialState = { showResults: false, recordCount: -1, noResults: false };
type State = Readonly<typeof initialState>;

let index = 0;

class Search extends React.Component<
	{
		currLanguage: any;
		id?: string;
	} & WrappedComponentProps
> {
	private refBg: HTMLDivElement;
	readonly state: State = initialState;

	private visible = false;
	private refSearchWrapper: HTMLDivElement;
	private refSearchInnerWrapper: HTMLDivElement;
	private searchInput: HTMLInputElement;

	private swiftype: any = null;

	//@ts-ignore:
	private renderResult(document_type, item) {
		index++;
		//@ts-ignore:
		return (`<p onkeydown="function focusClose(e){ if(e.nextSibling === null) { document.getElementById('searchClose').focus(); } }; focusClose(this.parentNode)" id="searchoutputresultslist${index}" tabindex="0" class="title">` + Swiftype.htmlEscape(item[`title`]) + `</p>`);
	}

	private searchFilters = () => {
		return {
			page: {
				language: this.props.currLanguage.langKey === 'ar-QA' ? 'ar' : 'en'
			}
		};
	};

	private onSearchResultClick = item => {
		navigate(item['url'].replace(window.location.origin, ''));
		if (this.visible) {
			this.toggleSearch(this);
		}
	};
	private onResults = (result: { page: any[] }) => {
		if (result.page.length === 0) {
			this.setState({ noResults: true });
		} else {
			this.setState({ noResults: false });
		}
		this.setState({ recordCount: result.page.length });
	};

	private hideListCallback = () => {
		this.setState({ showResults: false, noResults: false });
	};

	private showListCallback = () => {
		this.setState({ showResults: true });
	};

	private noResultsCallback = () => {
		this.setState({ showResults: false, noResults: true });
	};

	componentDidUpdate = prevProps => {
		if (prevProps.currLanguage.langKey !== this.props.currLanguage.langKey) {
			this.swiftype.clear();
		}
	};

	componentDidMount() {
		//See ../lib/swiftype/jquery.swiftype.autocomplete.js bottom of file for available options:

		//@ts-ignore:
		this.swiftype = jQuery('#st-search-input').swiftype({
			engineKey: 'aPuy66m8yLwUAuJ_ssRK',
			autocompleteContainingElement: '#st-search-results-container',
			renderFunction: this.renderResult,
			setWidth: false,
			resultLimit: 10,
			filters: this.searchFilters,
			onComplete: this.onSearchResultClick,
			hideListCallback: this.hideListCallback,
			showListCallback: this.showListCallback,
			noResultsCallback: this.noResultsCallback,
			resultReceived: this.onResults,
			noResultsMessage: 'No results'
		})[0].swiftype;

		TweenMax.set(this.refSearchWrapper, { autoAlpha: 0, yPercent: -50, force3D: true });
		TweenMax.set(this.refSearchInnerWrapper, { opacity: 0, y: -100, force3D: true });
	}

	private allResultsClickHandler = event => {
		event.preventDefault();
		navigate(Globals.CURRENT_LANGUAGE_PREFIX + '/search#all=1&allType=1&s=' + encodeURIComponent(this.searchInput.value));
		if (this.visible) {
			this.toggleSearch(this);
		}
		return false;
	};

	private toggleSearch = e => {
		if (!this.visible) {
			document.body.classList.add('search-open');
			if (e.currentTarget !== undefined) e.currentTarget.attributes['aria-pressed'] = true;
			document.addEventListener('keydown', this.keyHandler);
			this.visible = true;
			TweenMax.set(this.refBg, { visibility: 'visible', pointerEvents: 'all', force3D: true });
			TweenMax.to(this.refBg, 0.45, { opacity: 0.18, force3D: true });
			TweenMax.set(this.refSearchWrapper, { visibility: 'visible', pointerEvents: 'all', force3D: true });
			TweenMax.to(this.refSearchWrapper, 0.45, { opacity: 1, yPercent: 0, ease: EASE_CUSTOM_IN_OUT, force3D: true });
			TweenMax.to(this.refSearchInnerWrapper, 0.5, { opacity: 1, force3D: true });
			TweenMax.to(this.refSearchInnerWrapper, 0.6, {
				y: 0,
				ease: EASE_CUSTOM_IN_OUT,
				force3D: true,
				onComplete: () => {
					this.searchInput.focus();
				}
			});
		} else {
			document.removeEventListener('keydown', this.keyHandler);
			document.body.classList.remove('search-open');
			if (e.currentTarget !== undefined) e.currentTarget.attributes['aria-pressed'] = false;
			this.visible = false;
			TweenMax.to(this.refBg, 0.45, { autoAlpha: 0, force3D: true });
			TweenMax.set(this.refBg, { pointerEvents: 'none' });
			TweenMax.to(this.refSearchWrapper, 0.45, { autoAlpha: 0, yPercent: -50, ease: EASE_CUSTOM_IN_OUT, force3D: true });
			TweenMax.to(this.refSearchInnerWrapper, 0.25, { opacity: 0, force3D: true });
			TweenMax.to(this.refSearchInnerWrapper, 0.45, { y: -100, ease: EASE_CUSTOM_IN_OUT, force3D: true });
			TweenMax.set(this.refSearchWrapper, { pointerEvents: 'none' });
		}
	};

	private hideSearch = () => {
		if (this.visible) {
			this.toggleSearch(this);
		}
	};

	private shiftthefocus = event => {
		var checkifsearch = $('#st-search-results-container .swiftype-widget .autocomplete ul li').html();
		if (event.key == 'Tab') {
			if (checkifsearch == undefined) {
				$('#searchClose').focus();
			}
		} else if (event.key == 'Enter') {
			var searchquery = $('#st-search-input').val();
			window.location.href = Globals.CURRENT_LANGUAGE_PREFIX + '/search#all=1&allType=1&s=' + searchquery;
		}
	};

	private shiftthetabindexofsearch = event => {
		if (event.key == 'Enter') {
			$('#searchCloseiconbtn').attr('tabindex', 0);
		}
	};

	private shiftthetabindexofsearchtab = event => {
		if (event.key == 'Enter') {
			$('#searchCloseiconbtn').attr('tabindex', -1);
		}
	};

	render() {
		return (
			<div className={styles.wrapper}>
				<div onClick={this.hideSearch} ref={ref => (this.refBg = ref)} className={styles.searchBg} />
				<button
					id={this.props.id}
					tabIndex={-1}
					title={this.props.intl.formatMessage({ id: 'searchicontitle' })}
					aria-label={this.props.intl.formatMessage({ id: 'searchiconarialabel' })}
					onClick={this.toggleSearch}
					className={styles.searchIcon}
				>
					<div id="searchClose" className={styles.searchIconOpen} tabIndex={0} onKeyDown={this.shiftthetabindexofsearch} />
					<div className={styles.searchIconClosed} tabIndex={-1} id="searchCloseiconbtn" onKeyDown={this.shiftthetabindexofsearchtab} />
				</button>
				<div ref={ref => (this.refSearchWrapper = ref)} className={styles.searchWrapper} id="searchWrapper">
					<div ref={ref => (this.refSearchInnerWrapper = ref)} className={styles.innerWrapper + ' container-padding'}>
						<label htmlFor="st-search-input" className="visually-hidden">
							{this.props.intl.formatMessage({ id: 'SearchHelpText' })}
						</label>
						<input
							title={this.props.intl.formatMessage({ id: 'searchinputtitle' })}
							ref={ref => {
								this.searchInput = ref;
							}}
							placeholder={this.props.intl.formatMessage({ id: 'Search' })}
							id="st-search-input"
							className="st-search-input"
							type="text"
							onKeyDown={this.shiftthefocus}
						/>
						<a onClick={this.allResultsClickHandler} className={styles.allResultsLink + (this.state.showResults || this.state.noResults ? ' visible' : '')} href={'/search'}>
							<FormattedMessage id={this.state.noResults ? 'Retry this query with advanced search' : 'See all results'} />
						</a>
						<div id="st-search-results-container" className="st-search-results-container" />
					</div>
				</div>
			</div>
		);
	}

	private keyHandler = evt => {
		evt = evt || window.event;
		var isEscape = false;
		if ('key' in evt) {
			isEscape = evt.key === 'Escape' || evt.key === 'Esc';
		} else {
			isEscape = evt.keyCode === 27;
		}
		if (isEscape) {
			this.hideSearch();
		}
	};
}

export default injectIntl(Search);
