module.exports = {
	...require("./IDKT/en.js"),
	selectLanguage: 'Select your language',
	'email:': 'Email:',
	email: 'Email',
	'tel:': 'Tel:',
	Sections: 'Sections',
	Navigation: 'Qatar Foundation',
	Education: 'Education',
	Research: 'Research',
	Community: 'Community',
	All: 'All',
	Home: 'Home',
	event: 'Events',
	Article: 'Story',
	'Related Articles': 'Related Stories',
	place: 'Places',
	entity: 'Entities',
	program: 'Programs',
	campaign: 'Campaigns',
	people: 'People',
	page: 'Pages',
	article: 'Story',
	Story: 'Story',
	university: 'Universities',
	school: 'Schools',
	Class: 'Class',
	'Family Event': 'Family Event',
	Competition: 'Competition',
	press_release: 'Press Releases',
	singular_place: 'Place',
	singular_entity: 'Entity',
	singular_program: 'Program',
	singular_people: 'Person',
	singular_campaign: 'Campaign',
	singular_university: 'University',
	singular_school: 'School',
	singular_page: 'Page',
	singular_article: 'Story',
	singular_event: 'Event',
	singular_press_release: 'Press release',
	plural_place: 'Places',
	plural_event: 'Events',
	plural_entity: 'Entities',
	plural_program: 'Programs',
	plural_people: 'Persons',
	plural_campaign: 'Campaigns',
	plural_university: 'Universities',
	plural_school: 'Schools',
	plural_page: 'Pages',
	plural_article: 'Stories',
	plural_press_release: 'Press releases',
	plural_press_media_mention: 'Media Mentions',
	search_filter_school: 'Schools',
	search_filter_university: 'Universities',
	search_filter_program: 'Programs',
	search_filter_place: 'Places',
	search_filter_entity: 'Entities',
	search_filter_article: 'Stories',
	search_filter_event: 'Events',
	search_filter_people: 'People',
	search_filter_page: 'Pages',
	search_filter_press_release: 'Press Releases',
	search_filter_press_media_mention: 'Media Mentions',
	search_filter_list_entity: 'Entity',
	search_filter_list_place: 'Place',
	search_filter_list_type: 'Type',
	search_filter_list_part_of_qatar: 'Part of Qatar Foundation',
	search_filter_list_department: 'Department',
	search_filter_list_category: 'Category',
	search_filter_list_tags: 'Tags',
	search_filter_list_month: 'Month',
	search_filter_list_year: 'Year',
	search_filter_list_campaign: 'Campaign',
	search_filter_list_program: 'Program',
	programs_filter_list_schools: 'Filter by school',
	programs_filter_list_degrees: 'Filter by degree',
	search_category_article: 'Articles',
	search_category_event: 'Events',
	'Program & School': 'Program & School',
	Degree: 'Degree',
	Filters: 'Filters',
	Search: 'Search',
	Type: 'Type',
	Map: 'Map',
	'See on map': 'See on map',
	Back: 'Back',
	List: 'List',
	Share: 'Share',
	Fax: 'Fax',
	Phone: 'Phone',
	'E-mail': 'Email',
	Address: 'Address',
	opening_hours: 'Opening hours',
	'Offered at': 'Offered at',
	'Go to website': 'Go to website',
	'Click here to register': 'Click here to register',
	'Click here to join the seminar': 'Click here to join the seminar',
	'Related Events': 'Related Events',
	'Featured Events': 'Featured Events',
	'No results': 'No results',
	'search.result.plural': '{value} results found',
	'search.result.singular': '1 result found',
	'search.result.spellingsuggestion': 'Did you mean {link}?',
	'page.link': 'Learn more',
	'location.get_directions': 'Get directions',
	messageNoSearchStarted: 'No results, try a search query or choose a filter.',
	Date: 'Date',
	Time: 'Time',
	Where: 'Where',
	'Retry this query with advanced search': 'Retry this query with advanced search',
	'See all results': 'See all results',
	'Organized by': 'Organized by',
	path_entity_base: '/entity/',
	'Browse by Category': 'Browse by Category',
	'Browse all Events': 'Browse all events',
	'Browse all Media Mentions': 'Browse all media mentions',
	'Interested in more events?': 'Interested in more events?',
	in: ' | ',
	Schools: 'Schools',
	Universities: 'Universities',
	Programs: 'Programs',
	Entities: 'Entities',
	Places: 'Places',
	'image.pagination': 'Image {index} of {total}',
	'scrolllist.pagination': '{index} of {total}',
	'Q.': 'Q:',
	'A.': 'A:',
	'Press Releases': 'Press Releases',
	'Other Media': 'QF around the World',
	cookie_title: 'This website uses cookies',
	cookie_content: 'We use them to help our pages better meet user needs and improve the browsing experience.',
	'cookie.link': 'Learn more',
	'general-inquiries-footer-text': 'For general media inquiries,\n please contact',
	'balagh-qf-hotline-footer-text': 'Would you like to prevent or report fraud?\n Visit our',
	'Anti-Fraud and Whistle-Blowing Hotline': 'Anti-Fraud and Whistle-Blowing Hotline',
	SearchHelpText: 'Search Qatar Foundation Website',
	mmsctwtarialabel: 'Qatar Foundation Twitter Page',
	mmscfbarialabel: 'Qatar Foundation Facebook Page',
	mmsclinkedinarialabel: 'Qatar Foundation Linkedin Page',
	mmscsnapchatarialabel: 'Qatar Foundation Snapchat Profile',
	mmscinstaarialabel: 'Qatar Foundation Instagram Profile',
	mmsctwttitle: 'Qatar Foundation Twitter Page',
	mmscfbtitle: 'Qatar Foundation Facebook Page',
	mmsclinkedintitle: 'Qatar Foundation Linkedin Page',
	mmscsnapchattitle: 'Qatar Foundation Snapchat Profile',
	mmscinstatitle: 'Qatar Foundation Instagram Profile',
	imggallerymodnavprevtitle: 'Previous Image',
	imggallerymodnavprevarialabel: 'Previous Image',
	imggallerymodnavnexttitle: 'Next Image',
	imggallerymodnavnextarialabel: 'Next Image',
	sidescrollerstorylistmodnavprevtitle: 'Previous Image',
	sidescrollerstorylistmodnavprevarialabel: 'Previous Image',
	sidescrollerstorylistmodnavnexttitle: 'Next Image',
	sidescrollerstorylistmodnavnextarialabel: 'Next Image',
	scrolllistmodnavprevtitle: 'Previous',
	scrolllistmodnavprevarialabel: 'Previous',
	scrolllistmodnavnexttitle: 'Next',
	scrolllistmodnavnextarialabel: 'Next',
	closecookiedialogueboxclosebtntitle: 'Close The Cookie Dialogue Box',
	closecookiedialogueboxclosebtnarialabel: 'Close The Cookie Dialogue Box',
	Gototopbuttontitle: 'Go to top of the Website Button',
	Gototopbuttonarialabel: 'Go to top of the Website Button',
	openmenutitle: 'Open Menu',
	openmenuarialabel: 'Open Menu',
	headerlogotitle: 'Qatar Foundation Homepage',
	headerlogoarialabel: 'Qatar Foundation Homepage',
	QFlogomenubartitle: 'Qatar Foundations Logo',
	QFlogomenubararialabel: 'Qatar Foundations Logo',
	closemenutitle: 'Close Menu ',
	closemenuarialabel: 'Close Menu ',
	searchicontitle: 'Search Qatar Foundations Website',
	searchiconarialabel: 'Search Qatar Foundations Website',
	searchinputtitle: 'Enter Your Search Keyword',
	alert_title: 'Coronavirus (COVID-19) Updates',
	alert_content: 'For the latest COVID-19 information and updates from Qatar Foundation, please visit our ',
	'alert.link': 'Statements page',
	education_teaser: 'Discover our universities and schools',
	education_link: 'Explore Education at Qatar Foundation',
	research_teaser: 'Learn about our research initiatives',
	research_link: 'Explore Research at Qatar Foundation',
	community_teaser: 'Explore our community development programs',
	community_link: 'Explore Community at Qatar Foundation',
	education_city_title: 'This is Education City.',
	education_city_content:
		'Education City, our flagship initiative, is a pretty unique place. During just one short walk—or tram ride—around campus, you could be visiting an Ivy League university, cross the street to browse one of the region’s largest libraries, and then attend an open-mic at the neighboring university behind it.',
	education_city_link: 'Learn more',
	latest_news_title: 'Latest News',
	latest_news_content: "There's a lot going on throughout the year at Qatar Foundation. Here's some of our latest news.",
	latest_news_link: 'Browse all news',
	latest_stories_link: 'Browse all stories',
	More: 'More',
	stay_in_touch: "Let's stay in touch",
	follow_us_text: 'Want to see and read even more from us? Our social media channels have you covered with the latest.',
	sign_up_newsletter: 'Interested in our monthly curation of stories and events? Sign up for our newsletter.',
	sign_up_newsletter_exists: 'You have already subscribed to our monthly newsletter.',
	sign_up_newsletter_success: 'Thanks for subscribing.',
	sign_up_newsletter_invalid: 'Invalid email adress.',
	sign_up_newsletter_error: 'An error occured. please try again',
	email_validation: 'Please enter a valid email address',
	qr_campaign_title: 'THE UNREAD BECOMES THE FORGOTTEN.',
	qr_campaign_text: 'THE UNREAD BECOMES THE FORGOTTEN.\nDON’T LET YOUR POTENTIAL FADE AWAY.\nREAD AND IMAGINE THE FUTURE YOU\nHAVE ALWAYS WANTED.',
	qr_campaign_link: 'Learn more about Qatar Reads',
	qr_goto_qf_site_link: 'Proceed to Qatar Foundation website',
	'Browse Events by Location': 'Browse Events by Location',
	closealertdialogueboxclosebtntitle: 'Close The Alert Dialogue Box',
	closealertdialogueboxclosebtnarialabel: 'Close The Alert Dialogue Box',
	darkmode: 'Dark mode',
	lightmode: 'Light mode',
	push_success: 'Push subscription successful!',
	push_enable_error: 'An error occured while enabling push notifications. please try again',
	push_not_allowed: 'Notifications not allowed.',
	push_disabled: 'Push notifications disabled',
	push_disable_error: 'Error occured while disabling push notifications. please try again',
	push_generic_error: 'An error occured setting up push notification',
	name: "Full Name",
    email: "Email",
    title: "Job Title",
	company: "Company",
	nationality: "Nationality",
    country: "Country of Residence",
	name_validation: "Please enter a valid name",
    email_validation: " Please enter a valid email",
    title_validation: "	Please enter a valid title",
    company_validation: "Please enter a company name",
	select_validation: "Please select an option",
	subscribe_to_newsletter: "I would like to subscribe to the QF monthly newsletter",
	download_report: "Download report",
	form_submit_thankyou: "Thank you for filling in the form",
	download_report_text: "Click here to download the report file",
	mada_text: 'This website is accredited by Mada for accessibility',
	mada_logo: 'Mada Logo',
	search_filter_spokes_people: 'Expert Commentators',
	singular_spokes_people: 'Expert Commentators',
	plural_spokes_people: 'Expert Commentators',
	"How did you hear about the event?": "How did you hear about the event?",

	form_register_thankyou: "Thank you for registering.",
	webinar_link: "Webinar Link",
	add_to_calendar: "Add to calendar",
	register: "Register",
	registering: "Registering...",
	"Register to attend": "Register to attend",
	webinar_note: "You will soon receive an email from us with full details on how to watch the webinar.",

	ecss_title: "Education City Speaker Series",
	ecss_subtitle: "Best-selling author Layla F. Saad will reflect on her own experiences of racism and explain how we can all fulfill a social responsibility to identify and undo racism in our own communities, through our own actions.",
	join_webinar: "Join webinar",
	phone: "Phone number",
	title_of_the_artwork: "Title of the artwork",
	artists_statement: "Artist’s statement (100 words max)",
	artists_statement_info: "This statement will be displayed under the artwork on the website during the online exhibition. It’s a short description about how you approached your artistic practice and should include an interpretation for your imagery. Interpretations need not be literal or definitive, and should stimulate and invite viewers to contemplate the work within the context of the exhibition’s theme.",
	artists_biography: "Artist's biography (100 words max.)",
	artists_biography_info: "A short description of your artistic and professional background. The same will be on display next to your artwork on the website during the online exhibition.",
	artwork_dimensions: "Artwork dimensions",
	artwork_dimensions_info: "• Width, height and depth (if applicable)\n• In case of irregular shape artwork, an approximate dimension of its volume\n• In case of video, duration of the video",
	artwork_material: "Artwork material",
	artwork_material_info: "An explicit description of materials used to produce the art piece.",
	artwork_image: "Artwork image",
	artwork_image_info: "In case you would like to submit a video piece, please submit a screenshot of the recording here. You can submit the full video separately as a link in the following section.\n\nFile types: .zip, .jpg, .jpeg, .png, and .pdf\nSize limit: 10 MB (only one file per submission)",
	artwork_link: "Artwork link",
	artwork_link_info: "In case of animated digital artwork, video, or performance, please submit a link to the full recording here.",
	artwork_terms_conditions: "Terms & Conditions",
	artwork_copyrights_info: "Each artist can submit one piece of artwork.\n\nArtwork remains the ownership of the artist. However, once artwork is submitted, all digital submissions can be used by Qatar Foundation and its partners, and may be shared publicly, along with any identifying information you provide (images, etc) as part of the contest.",
	artwork_terms_conditions_info: "By submitting artwork (“the Work”) online, the Artist warrants that:",
	artwork_terms_conditions_info_1: "the Work is authentic and was created originally by the Artist",
	artwork_terms_conditions_info_2: "the Artist is the sole and absolute owner of the work, and that the Work is free and clear of any and all rights, claims, liens, mortgages, security interests, or other encumbrances held by any person",
	artwork_terms_conditions_info_3: "the Work does not infringe the rights of any person or entity, including trademark, copyright, privacy, and publicity rights.",
	artwork_terms_conditions_info_5: "Qatar Foundation has the right to:",
	artwork_terms_conditions_info_6: "review every submission before allowing any posts on the website",
	artwork_terms_conditions_info_7: "publish, share, and distribute any Work submitted",
	artwork_terms_conditions_info_8: "reproduce the Work online or print on (including not exclusively) its website, social networking platforms, and other online pages to promote the exhibition or for archival purposes",
	artwork_terms_conditions_info_9: "Qatar Foundation has the right to reject all:",
	artwork_terms_conditions_info_10: "incomplete submissions",
	artwork_terms_conditions_info_11: "submissions that do not reflect the exhibition’s purpose",
	artwork_terms_conditions_agree: "I agree to the terms & conditions",
	upload_placeholder: "Select file",
	required_field: "This field is required",
	invalid_phone: "Please enter a valid phone number",
	invalid_link: "Please enter a valid url",
	submit: "Submit",
	submiting: "Submiting...",
	file_size_limit_exceeded: "File size limit exceeded. (10MB max)",
	ppf_form_thankyou: "Thank you for your submission!",
	first_name: "First name",
	last_name: "Last name",
	qid: "Student QID",
	dob: "Date of birth",
	gender: "Gender",
	academic_gpa: "Academic GPA",
	upload_current_transcript: "Upload current transcript",
	current_school: "Current school",
	student_name: "Student name",
	student_phone: "Student phone number",
	student_email: "Student email",
	parent_phone: "Parent phone number",
	parent_email: "Parent email",
	confidential_references: "Confidential References",
	confidential_references_text: "Please send these recommendation questions to two current teachers and one current upper level school administrator. For applications to be reviewed, recommendations must be on official school letterhead and emailed to the Head of Admissions, Vicki G Wright (vwright@qf.org.qa), by Sunday, 22 November 2020. Please note that recommendations are confidential and should not be shared/viewed by parents and/or students.",
	recommendatio_questions_heading: "Recommendation Questions",
	recommendatio_question_1: "How long have you known this student?",
	recommendatio_question_2: "Please explain to the selection committee why you are recommending this student?",
	recommendatio_question_3: "What will this student bring to the Qatar Foundation community?",
	recommendatio_question_4: "Do you have any reservations about this student’s academic abilities and character?",
	recommendatio_question_5: "Any additional comments we should know about this student?",
	essay_topic: "Upload essay",
	essay_topic_question: "Why would you like to attend one of the Qatar Foundation schools and how will you contribute to our community?",
	essay_topic_text: "The essay is often the most important part of your application; it gives the scholarship committee a sense of who you are and your dedication to your goals. You’ll want to make sure that your scholarship essay is the best it can possibly be.",
	essays_formatting_heading: "Scholarship essays formatting:",
	essays_format_1: "Double spaced",
	essays_format_2: "Times New Roman font",
	essays_format_3: "12-point font",
	essays_format_4: "One-inch top, bottom, and side margins",
	other_useful_tips_heading: "Other useful tips to keep in mind include",
	other_useful_tip_1: "Think about what you are going to write and organize your thoughts into an outline.",
	other_useful_tip_2: "Write your essay by elaborating on each point you included in your outline.",
	other_useful_tip_3: "Use clear, concise, and simple language throughout your essay.",
	other_useful_tip_4: "Proofread and edit.",
	other_useful_tip_5: "Upload completed essay.",
	i_would_like_to_apply_to: "I would like to apply to: (please choose three different Qatar Foundation Schools)",
	first_choice_school: "1st Choice School",
	second_choice_school: "2nd Choice School",
	third_choice_school: "3rd Choice School",
	scholarship_terms: "I agree that I have read the above information. I understand this is the first step to being considered for the ‘Pre-University Education Merit Scholarship.’ I understand that I will be notified by email on 13 December 2020 if I have been selected to move to the next step. Students who have not been selected will also be notified by email.",
	date_placeholder: "mm/dd/yyyy",
	upload_recommendations: "Upload recommendations",
	upload_recommendations1: "Recommendation #1 (Current Teacher)",
	upload_recommendations2: "Recommendation #2 (Current Teacher)",
	upload_recommendations3: "Recommendation #3 (Upper-level Administrator)",
	upload_recommendations3: "Recommendation #3 (Upper-level Administrator)",
	qf_alumni_portal_launch_signup_heading: "We’ll be launching the official QF Alumni Portal in the coming months.",
	qf_alumni_portal_launch_signup_text: "Just enter your name and email address and we’ll notify you as soon as we launch!",
	notify_me: "Notify me",
	qf_brand_audit_heading: "We want to know how you perceive Qatar Foundation",
	qf_brand_audit_content: "If you have 5 minutes to spare, we would really appreciate you taking our short survey.",
	qf_brand_audit_cta: "Take the quick survey",
	"Register on the Education City App": "Register through the Education City app",
	youthOfChange_intro: "Here’s a glimpse of young and curious students of change from across our schools and universities.",
	innovatorsOfChange_intro: "Explore how our researchers and engaged community members are becoming students of change.",
	partnersOfChange_intro: "Check out some of our existing partnerships enabling students of change.",
	read_more: "Read more",
	read_less: "Read less",
	download_full_gallery: "Download full gallery",
	all_galleries: "All galleries",
	publications_projects : "Publications & Projects",
	affiliation: 'Affiliation',
	languages: 'Languages',
	publications: 'Publications',
	projects: 'Projects',
	book_interview: 'Book interview',
	book_interview_link: 'mailto:pressoffice@qf.org.qa?subject=Book%20interview%20with%20%3Ctitle%3E',
	editors_picks: "Editor’s Picks",
	select_dropdown: 'Select...',
	soc_survey_popup_heading: "What are your thoughts on the Students of Change campaign?",
	soc_survey_popup_cta: "Take this 5-minute, anonymous survey and let us know what’s on your mind."
};
